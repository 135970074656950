import React from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {Select, Button} from 'antd'

// Previously Project filters could be set per user type.  The last working version of this functionality is in
// commit f1e6ad51210f6b7ab49055dc55e076109423fb0c on 1/13/2020 but was removed because all user types used the same filters in all cases.

const Clients = ({ value, setFilters, clientTypes }) => (
  <Select
    size="large"
    mode="multiple"
    placeholder="All Clients"
    onChange={(values) => setFilters(`clients`, values)}
    style={{ margin: `0 1rem 0 0`, flex: 1 }}
    value={value}
  >
    {clientTypes.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name }) => (
      <Select.Option key={id} value={id}>
        {name}
      </Select.Option>
    ))}
  </Select>
);


const Intakes = ({value, setFilters, intakeTypes}) => (
  <Select
    size="large"
    placeholder="All Intakes"
    onChange={(values) => setFilters(`intakes`, values)}
    value={value}
    style={{margin: `0 1rem 0 0`, flex: 1}}
  >
    {intakeTypes.sort().map((t) => (
      <Select.Option key={t}>{t}</Select.Option>
    ))}
  </Select>
)

const durationMap = {
  'last week': moment().subtract(7, 'days'),
  'last month': moment().subtract(1, 'months'),
  'last 3 months': moment().subtract(3, 'months'),
  'last 6 months': moment().subtract(6, 'months'),
  'last a year': moment().subtract(1, 'years'),
};

const Timespan = ({ value, setFilters }) => {
  const defaultValue = 'last 3 months'; // Default to last 3 months

  return (
    <Select
      size="large"
      placeholder="All Dates"
      onChange={(value) => setFilters(`timespan`, durationMap[value])}
      style={{ margin: `0 1rem 0 0`, flex: 1 }}
      value={value ? `Since ${value.toDate().toDateString()}` : defaultValue}
    >
      {Object.keys(durationMap).map((t) => (
        <Select.Option key={t}>{t}</Select.Option>
      ))}
    </Select>
  );
};


const componentMap = {
  clients: Clients,
  intakes: Intakes,
  timespan: Timespan,
  blank: () => <div style={{flex: 1}}></div>,
}

const Filters = ({auth, filters, clearFilters, setFilters, intakeTypes = [], clientTypes = []}) => (
  <div className="padding-left-1 padding-right-1 flex-row align-center">
    {[`clients`, `intakes`, `timespan`, `blank`].map((category, i) => {
      const C = componentMap[category]
      return (
        <C
          key={i}
          value={filters[category]}
          setFilters={setFilters}
          intakeTypes={intakeTypes}
          clientTypes={clientTypes}
        />
      )
    })}
    <Button type="primary" onClick={clearFilters}>
      Clear Filters
    </Button>
    <Select
      size="large"
      placeholder="Saved Filters"
      onChange={() => console.log(`changed`)}
      style={{margin: `0 0 0 1rem`, flex: 1}}
    >
      {auth.sunkaizenUser &&
        (auth.sunkaizenUser.projectFilters || [])
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((filter) => (
            <Select.Option key={filter.name}>
              <Link
                to={{
                  pathname: `/projects`,
                  state: {
                    savedFilter: {
                      pagination: filter.pagination,
                      filters: filter.filters,
                      sorter: filter.sorter,
                    },
                  },
                }}
              >
                {filter.name}
              </Link>
            </Select.Option>
          ))}
    </Select>
  </div>
)

export default Filters