import {headers, headerMap} from '../../CreateCSVReports/utilities/headerOrder'
import {iterateIntake} from '.'
const filteredHeaders = headers.filter(header => header !== 'Client ID');

export default function formatProjects(projects) {
  if (!projects || !projects.length) return null

  const header = makeHeader(projects)
  const rows = projects.map((project) => formatProject(project))

  return [header, ...rows]
}

function formatProject(project) {
  const hm = headerMap(project)
  const basicInformation = filteredHeaders.map((h) => hm[h])

  const scoringInformation = getScoringInformation(project)

  return [...basicInformation, project.intake.id, project.rubric.id, ...scoringInformation]
}

function makeHeader(projects) {
  const scoringHeader = iterateIntake(projects[0], (s, f) => {
    const prefix = `${s.id}.${f.id}`
    return [`${prefix}.question`, `${prefix}.answer`, `${prefix}.score`]
  }).flat()
  //console.log('scoring header',scoringHeader)
  const scoringHeadersLabels = []
  scoringHeader.forEach((eachHeader) => {
      const parts =  eachHeader.split(".")
      const readable = projects[0].intake.sections[parts[0]].fields[parts[1]].label ?? ''
      readable.length > 0 ? readable : eachHeader
      scoringHeadersLabels.push(readable.length > 0 ? `${readable}:${parts[2]}` : eachHeader)
  })
  //console.log('scoringHeadersLabels',scoringHeadersLabels)
  return [...filteredHeaders, 'Form ID', 'Rubric ID', ...scoringHeadersLabels]
}

function getScoringInformation(project) {
  return iterateIntake(project, (s, f) => [f.question, f.answer, f.score]).flat()
}
